import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'

import '@/utils/styles/init.css' // 引入初始化css文件
import '@/utils/styles/my_element.less'
import 'animate.css'; // css动画库

import '@/utils/styles/768/index.less' // 768px以下移动端样式适配

import { useFlexibleRem } from '@/utils/js/flexible' // 引入rem 自适应文件
useFlexibleRem()

// createApp(App).use(store).use(router).mount('#app')
createApp(App).use(router).mount('#app')
