import axios from 'axios';
import '@/utils/styles/my_element.less';

// element-loading配置
import { loadingOptions } from './config/loading';
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // 携带cookie
  withCredentials: true
});

// 加载特效实例
let loadingInstance;

// 配置请求拦截器
request.interceptors.request.use(config => {
  // 全屏加载特效启动
  if (loadingInstance) loadingInstance.close();
  loadingInstance = ElLoading.service(loadingOptions);
  return config;
}, error => {
  // 请求错误时的处理
  return Promise.reject(error);
});

// 配置响应拦截器
request.interceptors.response.use(response => {
  loadingInstance.close();
  if (response.data.result !== 0) ElMessage.error('请求异常'); // http请求成功，但后端响应异常
  return response;
}, error => {
  // http请求异常
  loadingInstance.close();
  if (error.response) {
    ElMessage.error(error.response.statusText);

    // 服务器报错时,拦截信息,防止界面报错
    return Promise.resolve({
      data: {
        result: parseInt(error.response.status),
        error: error.response.statusText
      }
    });
  }
  return Promise.reject(error);
});
export default request;