import request from '@/utils/request'

// 请求所有文章
export const articleDirectory = (data) => {
    return request({
        url: '/web/articleDirectory',
        method: 'post',
        data
    })
}
// 请求首页文章
export const homePageContent = () => {
    return request({
        url: '/web/homePageContent',
        method: 'post'
    })
}
// 请求文章内容
export const getArticleDetailById = (params) => {
    return request({
        url: '/web/getArticleDetailById',
        method: 'post',
        params
    })
}
// 请求产品列表
export const getProductList = () => {
    return request({
        url: '/web/getProductList',
        method: 'post'
    })
}
// 请求公司基本信息
export const getCompanyInfo = () => {
    return request({
        url: '/web/getCompanyInfo',
        method: 'post'
    })
}
// 请求站点信息
export const getSiteInfo = () => {
    return request({
        url: '/web/getSiteInfo',
        method: 'post'
    })
}