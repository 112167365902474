import { createRouter, createWebHistory } from 'vue-router'

import Common from '@/components/Common' // 组件-公共模块

const routes = [
  // 路由重定向common公共模块
  { path: '/', redirect: '/common' },
  // 公共模块
  {
    path: '/common',
    name: 'common',
    meta: { isBreadcrumb: false },
    component: Common,
    children: [
      // 公共模块重定向至主页
      { path: '/common', redirect: '/common/home' },
      // 组件-主页
      {
        path: '/common/home',
        name: 'home',
        meta: { title: '主页', icon: 'HomeFilled', isBreadcrumb: true },
        component: () => import('@/views/Home')
      },
      // 组件-产品服务
      {
        path: '/common/production',
        name: 'production',
        meta: { title: '产品服务', icon: 'Opportunity', isBreadcrumb: true },
        component: () => import('@/views/Production')
      },
      // 组件-科普园地
      {
        path: '/common/science',
        name: 'science',
        meta: { title: '科普园地', icon: 'List', isBreadcrumb: true },
        component: () => import('@/views/Science')
      },
      // 组件-新闻资讯
      {
        path: '/common/news',
        name: 'news',
        meta: { title: '新闻资讯', icon: 'Comment', isBreadcrumb: true },
        component: () => import('@/views/News'),
        children: [
          {
            path: '/common/news/team',
            name: 'team',
            meta: { title: '团队新闻', isBreadcrumb: true },
            component: () => import('@/views/News/NewsTeam'),
          },
          {
            path: '/common/news/media',
            name: 'media',
            meta: { title: '媒体报道', isBreadcrumb: true },
            component: () => import('@/views/News/NewsMedia'),
          },
          {
            path: '/common/news/vocation',
            name: 'vocation',
            meta: { title: '行业动态', isBreadcrumb: true },
            component: () => import('@/views/News/NewsVocation'),
          },
        ]
      },
      // 组件-关于我们
      {
        path: '/common/about',
        name: 'about',
        meta: { title: '关于我们', icon: 'UserFilled', isBreadcrumb: true },
        component: () => import('@/views/About')
      },
      // 组件-联系我们
      {
        path: '/common/call',
        name: 'call',
        meta: { title: '联系我们', icon: 'Promotion', isBreadcrumb: true },
        component: () => import('@/views/Call')
      },
      // 组件-文章详情页
      {
        path: '/common/detail',
        name: 'detail',
        meta: { title: '详情页', isBreadcrumb: true, mode: 1 }, // mode: 1 面包屑
        component: () => import('@/views/Detail')
      },
    ]
  },
  // 错误页面模块
  {
    path: '/error',
    name: 'error',
    children: [
      { path: '/error', redirect: '/error/404' },
      // 组件-错误页面-404
      {
        path: '/error/404',
        name: '404',
        component: () => import('@/views/Error/404')
      }
    ]
  },
  // 匹配不存在的路由
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('@/views/Error/404')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
