// element plus icon 映射模块
import { HomeFilled, Opportunity, List, Comment, UserFilled, Promotion } from '@element-plus/icons-vue'

export const iconMapping = {
    "HomeFilled": HomeFilled,
    "Opportunity": Opportunity,
    "List": List,
    "Comment": Comment,
    "UserFilled": UserFilled,
    "Promotion": Promotion
}